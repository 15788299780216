import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import urls from "../constants/urls"

function SEO({ description, lang, meta, title, url, imageUrl }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description;
  url = `${urls.BASE_URL}${url}`;
  imageUrl = imageUrl || `${urls.BASE_URL}/mike-social.png`;
  title = title ? `${title} | Mike` : site.siteMetadata.title;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`, 
          content: imageUrl
        },
        {
          property: `og:url`, 
          content: url
        },
        {
          property: `og:site_name`, 
          content: 'Mike'
        },
        {
          property: `fb:app_id`,
          content: '1317412468288248'
        },    
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: imageUrl
        },
        {
          name: `twitter:url`,
          content: url
        },
        {
          name: `twitter:site`,
          content: '&#64;mikeappofficial'
        },
        {
          name: `twitter:domain`,
          content: 'mikeapp.co'
        }
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO